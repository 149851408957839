import React from "react";
import { graphql } from "gatsby";
import ProductionProgrammeIntro from "../components/page-components/ProductionProgrammes/ProductionProgrammesIntro";
import PluginKeyFeatures from "../components/reusable/PluginKeyFeatures";
import PluginQuoteSlider from "../components/reusable/PluginQuoteSlider";
import PluginTextImageBlock from "../components/reusable/PluginTextImageBlock";
import PluginAccordionBlock from "../components/reusable/PluginAccordionBlock";
import PluginSystemRequirements from "../components/reusable/PluginSystemRequirements";
import Seo from "../components/global/seo";

export default function ProductPlugin(props) {
  const pageData = props.data.wpProduct;
  const path = props.location.pathname;

  function camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }
  return (
    <div>
      <Seo title={pageData.seo.title} description={pageData.seo.metaDesc} />
      <ProductionProgrammeIntro
        video={pageData.acf_product_info.plugins.pluginTrailerVideo}
        introVideoObjectFit={"contain"}
        title={pageData.title}
        path={path}
        category={"plugin"}
        introText={pageData.acf_product_info.plugins.description}
        trailDownloadMac={
          pageData?.acf_product_info?.plugins?.zipFileMac?.localFile?.publicURL
        }
        trailDownloadWindows={
          pageData?.acf_product_info?.plugins?.zipFileWindows?.localFile
            ?.publicURL
        }
        snipcart_meta={camelize(pageData.title) + "-" + pageData.id}
        snipcart_plugin={true}
        snipcart_description={
          pageData.acf_product_info.plugins.briefProductDescription
        }
        snipcart_id={pageData.databaseId}
        snipcart_price={pageData.acf_product_info.plugins.price}
        snipcart_image={
          pageData?.acf_product_info?.plugins?.pluginThumbnailImage.localFile
            .publicURL
        }
        snipcart_guid={pageData.acf_product_info.plugins.snipcartGuid}
      />

      <PluginTextImageBlock
        data={pageData.acf_product_info.plugins.textImageBlock}
        trailDownloadMac={
          pageData?.acf_product_info?.plugins?.zipFileMac?.localFile?.publicURL
        }
        trailDownloadWindows={
          pageData?.acf_product_info?.plugins?.zipFileWindows?.localFile
            ?.publicURL
        }
        layout={"text-left-image-right"}
      />
      <PluginTextImageBlock
        data={pageData.acf_product_info.plugins.accordionImageBlock}
        trailDownloadMac={
          pageData?.acf_product_info?.plugins?.zipFileMac?.localFile?.publicURL
        }
        trailDownloadWindows={
          pageData?.acf_product_info?.plugins?.zipFileWindows?.localFile
            ?.publicURL
        }
        layout={"text-right-image-left"}
      />
      <PluginAccordionBlock
        data={pageData.acf_product_info.plugins.accordionImageBlock}
      />
      <PluginKeyFeatures
        title={pageData.title}
        video={pageData.acf_product_info.plugins.featuresVideo}
        features={pageData.acf_product_info.plugins.pluginFeatures}
      />
      <PluginQuoteSlider quotes={pageData.acf_product_info.plugins.quotes} />
      <PluginSystemRequirements
        mac={pageData.acf_product_info.plugins.macInfo}
        windows={pageData.acf_product_info.plugins.windowsInfo}
        daw={pageData.acf_product_info.plugins.dawInfo}
        tutorialVid={pageData.acf_product_info.plugins.pluginTrailerVideo}
      />
    </div>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpProduct(id: { eq: $id }) {
      id
      seo {
        title
        metaDesc
      }
      title
      databaseId
      acf_product_info {
        plugins {
          dawInfo
          description
          snipcartGuid
          briefProductDescription
          accordionImageBlock {
            accordion {
              accordionText
              accordionTitle
            }
            accordionTitle
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            title
            text
          }
          pluginFeatures {
            feature
          }
          macInfo
          pluginTrailerVideo
          pluginThumbnailImage {
            localFile {
              publicURL
            }
          }
          price
          quotes {
            name
            quote
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          salePrice
          snipcartGuid
          textImageBlock {
            buttonLink
            buttonText
            isDownloadTrialButton
            text
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          zipFileMac {
            localFile {
              publicURL
            }
          }
          zipFileWindows {
            localFile {
              publicURL
            }
          }
          featuresVideo
          windowsInfo
        }
      }
    }
  }
`;
